import auth from '../services/authService';
import React, { useState } from 'react';
import { useAsync } from 'react-async-hook';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { getMyTests, getMyTestRecords } from '../services/testService';
import Tutorial from './tutorialFlow/Tutorial';

interface TestButtonProps {
  testname: string;
  label?: string;
}

interface TestProgressProps {
  nComplete: number;
  nRemaining: number;
}

interface TestRecord {
  nComplete: number;
  nStimuli: number;
  name: string;
}

const TestButton: React.FC<TestButtonProps> = ({ testname, label }) => (
  // Optional button label - defaults to testname
  <Button
    id={testname}
    key={testname}
    className="m-2"
    href={`/runTest/${testname}`}
  >
    {label || testname}
  </Button>
);

const TestProgress: React.FC<TestProgressProps> = ({
  nComplete,
  nRemaining,
}) => (
  // Show progress through tests
  // Currently using a progress bar but might need redesigning
  // Text on the progress bar is very small

  <React.Fragment>
    <Card>
      <Card.Body>
        <Card.Title>You have {nRemaining} tests left to complete.</Card.Title>
        <Card.Text>
          <ProgressBar
            style={{ height: '40px' }}
            variant="success"
            striped
            now={(100 * nComplete) / (nRemaining + nComplete)}
          />
        </Card.Text>
      </Card.Body>
      {nRemaining === 0 && (
        <Card.Footer>You have completed all the tests! Thank you!</Card.Footer>
      )}
    </Card>
  </React.Fragment>
);

const retryAsyncFunction = async (fn, retries = 3, delay = 1000) => {
  try {
    return await fn();
  } catch (error) {
    if (retries > 0) {
      console.warn(`Retrying... ${retries} attempts left.`);
      await new Promise((resolve) => setTimeout(resolve, delay));
      return retryAsyncFunction(fn, retries - 1, delay);
    } else {
      throw error;
    }
  }
};

function Welcome() {
  const user = auth.getCurrentUser();
  console.log('welcome.jsx', user);
  console.log('Token:', auth.getJwt());
  const asyncData = useAsync(getMyTests, []);
  const asyncData2 = useAsync(() => retryAsyncFunction(getMyTestRecords), []);
  console.log('asyncData2', asyncData2);
  console.log('asyncData', asyncData);

  const [inTutorial, setInTutorial] = useState(false);

  // Handle loading state
  if (asyncData2.loading) return <p>Loading test records...</p>;

  // Handle error state
  if (asyncData2.error)
    return <p>Error loading test records: {asyncData2.error.message}</p>;

  const testRecords: TestRecord[] | undefined = asyncData2.result;
  console.log('testrecords', testRecords);

  const nTests = testRecords?.length;
  const incompleteTests = testRecords?.filter((t) => t.nComplete < t.nStimuli);
  let nRemaining = 0;

  if (incompleteTests) nRemaining = incompleteTests.length;

  console.log(nRemaining, nTests);
  console.log('incomplete', incompleteTests);

  const display = inTutorial ? (
    // Doing the pre-test checks
    <Tutorial onComplete={() => setInTutorial(false)}></Tutorial>
  ) : (
    <React.Fragment>
      <h1>Welcome</h1>
      <p />
      If this is your first visit, please read the tutorial.
      <p />
      {nTests > 0 && (
        <TestProgress nRemaining={nRemaining} nComplete={nTests - nRemaining} />
      )}
      <p />
      {!asyncData.loading &&
        !asyncData2.loading &&
        incompleteTests.length > 0 && (
          <React.Fragment>
            <Button className="m-2" onClick={() => setInTutorial(true)}>
              Read the tutorial
            </Button>
            <Link to="/contactus" className="btn btn-primary">
              Contact Us
            </Link>
            <TestButton
              testname={incompleteTests[0].name}
              label="Start next test"
            />
          </React.Fragment>
        )}
    </React.Fragment>
  );

  return display;
}

export default Welcome;
