import React, { Component, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ProgressBar from 'react-bootstrap/ProgressBar';
import queryString from 'query-string';
import { RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc';
import PretestChecks from './pretestChecks';
import { Link } from 'react-router-dom';
import ReactHowler from 'react-howler';

import auth from '../services/authService';
import audio from '../services/audioService';
import {
  getTest,
  updateTestRecord,
  getMyTestRecords,
} from '../services/testService';

import { useAsync } from 'react-async-hook';

const phases = {
  PRE: 'Pre',
  LISTEN: 'Listen',
  SPEAK: 'Speak',
};

const RECORDING_TIMEOUT_MS = 30000;
// Number of milliseconds before speech recording will silently timeout
// 30 seconds - much longer than longest expected response
// This timeout is a precaution against users leaving running while
// going to answer the phone without using the abort button.
class ListeningTestInner extends Component {
  constructor(props) {
    super(props);
    const user = auth.getCurrentUser();

    const volume_string = localStorage.getItem(`preferredVolume:${user.email}`);
    this.volume = volume_string
      ? Number(volume_string.replace(/['"]+/g, ''))
      : 50;
    console.log('listeningTest.jsx: volume', this.volume);

    // TODO: The volume should not be null. If it is then it means the user
    // has not set their volume preference and should not have reached this page.
    this.isRecording = false;

    this.state = {
      test: undefined,
      testName: undefined,
      stimuli: '',
      stimuli_ids: '',
      volume: this.volume, //.replace(/"/g, ''),
      index: 1, // Skip index 0 - levelcheck signal
      nComplete: 0,
      nWarmup: 0, // Number of stimuli used for warmup
      test_type: '', // test type can be "speech" or "noise"
      isBlocked: false,
      isPlaying: false,
      user: user,
      allDone: false, // Do if experiment complete
      doAbort: false, // True if user clicks abort
      phase: phases.PRE, // can be "pre", "listen", "speak"
    };
  }

  next = async () => {
    //if (this.isRecording)
    const test = this.state.test;
    if (this.state.index > this.state.nComplete) {
      this.setState({ nComplete: this.state.index });
      // User's test progress record is updated on the database
      // var result = await updateTestRecord(test, this.state.nComplete);
    }
    if (this.recorder !== undefined) await this.stopRecording();
    if (this.state.index < this.state.stimuli.length) {
      this.startRecording();
      this.setState({
        isPlaying: true,
        index: this.state.index + 1,
        phase: phases.LISTEN,
      });
    } else {
      updateTestRecord(test, this.state.nComplete);
      this.setState({ allDone: true });
    }
  };

  startRecording = async () => {
    console.log('listeningTest.jsx start recording');
    if (this.state.isBlocked) {
      console.log('listeningTest.jsx: Permission Denied');
    } else {
      this.recorder = new RecordRTCPromisesHandler(this.stream, {
        type: 'audio',
        mimeType: 'audio/wav',
        recorderType: StereoAudioRecorder,
        desiredSampRate: 16000, // Notice name is 'SampRate' not 'SampleRate'
        numberOfAudioChannels: 1,
      });

      this.recorder.recordRTC.setRecordingDuration(RECORDING_TIMEOUT_MS);
      this.recorder.startRecording();
      this.isRecording = true;
    }
  };

  make_response_fn = (testname, index, volume, stimname) => {
    return `response#${testname}#S${index}#vol${volume}#${stimname}#${
      this.state.user.email
    }`;
  };

  stopRecording = async () => {
    console.log('listeningTest.jsx: in stop recording');

    // Make name carry info
    const index = this.state.index;
    const name = this.make_response_fn(
      this.state.testName,
      index - 1,
      this.volume,
      this.state.stimuli[index - 1].filename
    );

    // Stop the recorder if it hasn't already stopped via the time out
    console.log('listeningTest.jsx: recorder state', this.recorder.getState());
    const recorder_state = await this.recorder.getState();
    console.log('listeningTest.jsx: recorder state', recorder_state);
    if (recorder_state === 'recording') {
      console.log('listeningTest.jsx: stopRecording()');
      await this.recorder.stopRecording();
    }
    const blob = await this.recorder.getBlob();
    console.log('listeningTest.jsx: stopped recording: ', blob);
    audio.saveTrack('response', this.state.user, name, blob);
  };

  async componentDidMount() {
    const testName = this.props.testname;
    const test = this.props.test;
    const nComplete = this.props.ncomplete;

    this.setState({
      test,
      testName,
      nComplete: nComplete,
      nWarmup: test.nWarmup,
      test_type: test.type,
      index: Math.max(1, nComplete - test.nWarmup),
      phase: phases.PRE,
    });

    if (this.props.location) {
      const values = queryString.parse(this.props.location.search);
      if (values.index) this.setState({ index: parseInt(values.index) });
    }

    this.setState({ stimuli: test.stimuli });

    this.stream = await navigator.mediaDevices.getUserMedia(
      { audio: true },
      () => {
        console.log('Permission Granted');
        this.setState({ isBlocked: false });
      },
      () => {
        console.log('Permission Denied');
        this.setState({ isBlocked: true });
      }
    );
  }

  //for rearrange prop
  onFinishedPlaying = () => {
    this.setState({ isPlaying: false, phase: phases.SPEAK });
  };

  doAbort = () => {
    console.log('Clicked abort');
    this.setState({ doAbort: true });
    updateTestRecord(this.state.test, this.state.nComplete);
  };

  doCancelAbort = () => {
    console.log('Cancelling abort');
    // Step back one
    this.setState({
      index: Math.max(1, this.state.index - 1),
      doAbort: false,
      phase: phases.PRE,
    });
  };

  renderListening(
    blobURL,
    volume,
    stimuli,
    test_type,
    index,
    isPlaying,
    allDone,
    phase
  ) {
    const renderTestType = () => {
      let text;
      // if (test_type === "speech") {
      //  text = "You will hear two talkers. Repeat the 2nd talker.";
      //} else {
      //  text = "You will hear speech and noise. Repeat the speech.";
      // }
      if (test_type === 'male') {
        text = 'Repeat the male target talker.';
      } else {
        text = 'Repeat the female target talker.';
      }
      return (
        <Alert variant="warning">
          <h4>{text}</h4>
        </Alert>
      );
    };

    const renderNextButtonLabel = () => {
      let text = 'Next';
      if (stimuli && index === stimuli.length) text = 'Finish';
      return text;
    };

    const renderListenTalkStatus = () => {
      let text, variant;
      switch (phase) {
        case phases.PRE:
          text = 'Press Next to start';
          variant = 'warning';
          break;
        case phases.LISTEN:
          text = 'Listen';
          variant = 'info';
          break;
        case phases.SPEAK:
          text = 'Speak';
          variant = 'success';
          break;
        default:
          // This should never happen
          text = 'APPLICATION ERROR';
          variant = 'danger';
          break;
      }
      return (
        <Alert variant={variant}>
          <h4>{text}</h4>
        </Alert>
      );
    };
    return (
      <React.Fragment>
        <h1>Listen and repeat Volume </h1>
        <p>
          Press &lsquo;Next&rsquo; to listen and then clearly repeat the words
          that you hear. When you have finished speaking, press
          &lsquo;Next&rsquo; to move on to the next example. The bar below will
          show your progress. Continue until the session is complete.
        </p>
        {renderTestType()}
        {renderListenTalkStatus()}
        {Math.max(0, index - 1)} of {stimuli && stimuli.length - 1}
        <ProgressBar
          now={stimuli && (100 * (index - 1)) / (stimuli.length - 1)}
        />
        <p />
        <Row>
          {blobURL && (
            <ReactHowler
              playing={isPlaying}
              volume={volume / 100}
              preload={true}
              loop={false}
              format={['mp3', 'aac']}
              src={blobURL}
              onEnd={this.onFinishedPlaying}
            />
          )}

          <ButtonToolbar>
            <Button variant={'danger'} className="m-2" onClick={this.doAbort}>
              {'Leave'}
            </Button>
            <Button
              variant="primary"
              className="m-2"
              onClick={this.next}
              disabled={
                !stimuli || isPlaying || index > stimuli.length || allDone
              }
            >
              {renderNextButtonLabel()}
            </Button>
          </ButtonToolbar>
        </Row>{' '}
      </React.Fragment>
    );
  }

  renderThanks() {
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Img variant="top" className="my-2" src="/thumbs-up.png" />
        <Card.Body>
          <Card.Title>Thank you!</Card.Title>
          <Card.Text>Now take a break.</Card.Text>
          <Button href="/welcome" variant="primary">
            Return to Tasklist
          </Button>
        </Card.Body>
      </Card>
    );
  }

  renderAbort() {
    return (
      <Modal show={this.doAbort} onHide={this.doCancelAbort}>
        <Modal.Header closeButton>
          <Modal.Title>Quit the test</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to quit?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.doCancelAbort}>
            No. Please continue.
          </Button>
          <Link to="/welcome" className="btn btn-primary">
            Yes. Please quit.
          </Link>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const {
      test,
      volume,
      stimuli,
      test_type,
      index,
      isPlaying,
      allDone,
      phase,
    } = this.state;
    let blobURL = null;
    if (test && stimuli && index > 0 && index <= test.stimuli.length) {
      const stim_name = test.stimuli_ids[index - 1];
      const current_stimulus = stimuli.find((obj) => {
        return obj.filename === stim_name;
      });
      blobURL = current_stimulus ? current_stimulus.metadata.blobURL : null;
    }

    console.log('listeningTest.jsx: volume', volume);
    return (
      <Container>
        {this.state.doAbort && this.renderAbort()}
        {!allDone
          ? this.renderListening(
              blobURL,
              volume,
              test ? test.stimuli : null,
              test_type,
              index,
              isPlaying,
              allDone,
              phase
            )
          : this.renderThanks()}
      </Container>
    );
  }
}

const fetchListeningTest = async (
  testName,
  setTest,
  setTestData,
  setNComplete
) => {
  const test = await getTest(testName);
  setTest(test);

  const myTestRecords = await getMyTestRecords();

  const this_test = myTestRecords.filter((t) => t.name === testName);
  const nComplete = this_test[0].nComplete;
  setNComplete(nComplete);
  // Tracks need to be tagged as coming from the 'stimuli' tracklist
  // This complication needs refactoring...
  const trackList = test.stimuli;
  for (const track of trackList) {
    track.listName = 'stimuli';
  }

  // Fetch the audio data from the server.
  audio.fetchTracks(trackList, null, (r) => {
    setTestData(r);
  });
};

const ListeningTest = (props) => {
  const [inSetup, setInSetup] = useState(true);
  const [test, setTest] = useState(false);
  const [testData, setTestData] = useState(false);
  const [nComplete, setNComplete] = useState(false);
  const testName = props.params.testName;
  useAsync(fetchListeningTest, [testName, setTest, setTestData, setNComplete]);

  // The first test item is the signal to use for checking levels
  const levelCheckURL =
    testData.recordings && testData.recordings[0].metadata.blobURL;

  const display =
    testData.recordings && inSetup ? (
      // Doing the pre-test checks
      <PretestChecks
        onComplete={() => setInSetup(false)}
        levelCheckAudio={levelCheckURL}
      />
    ) : testData.recordings && test ? (
      // Doing the actual testing
      <ListeningTestInner
        testname={testName}
        test={test}
        ncomplete={nComplete}
      ></ListeningTestInner>
    ) : (
      // Data not ready...
      <p>Waiting for data...</p>
    );

  return <React.Fragment>{display}</React.Fragment>;
};

export default ListeningTest;
